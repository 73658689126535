import request from "@/utils/request";

// 每日一句列表
export function DailyOneList(params) {
    return request({
        url: '/admin/french/dailyOneList',
        method: 'get',
        params
    })
}

// 删除每日一句
export function DailyOneDel(data) {
    return request({
        url: '/admin/french/dailyOneDel',
        method: 'delete',
        data
    })
}

// 添加APP每日一句
export function DailyOneAdd(data) {
    return request({
        url: '/admin/french/dailyOneAdd',
        method: 'post',
        data
    })
}

// 编辑每日一句
export function DailyOneEdit(data) {
    return request({
        url: '/admin/french/dailyOneEdit',
        method: 'put',
        data
    })
}

// 每日一句上传COS签名
export function getDailyOneCosToken(params) {
    return request({
        url: '/admin/french/getDailyOneCosToken',
        method: 'get',
        params
    })
}
